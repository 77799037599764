import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../components/layout";

export default function LogoutPage() {
  const [searchParams] = useSearchParams();

  const [iFrameUrl, setIFrameUrl] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect((): void => {
    fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
      body: JSON.stringify({
        logoutId: searchParams.get("logoutId"),
        redirectUri: searchParams.get("post_logout_redirect_uri"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then(async (response: Response) => {
        if (response.ok) {
          var responseObject = await response.json();
          if (responseObject.postLogoutRedirectUri)
            window.location.replace(decodeURIComponent(responseObject.postLogoutRedirectUri));
          return responseObject;
        } else {
          throw new Error("Logout request unsuccessful!");
        }
      })
      .then(({ iFrameUrl } = {}): void => {
        setIFrameUrl(iFrameUrl);
      })
      .finally((): void => {
        setLoading(false);
      });
  }, [searchParams, setIFrameUrl, setLoading]);

  return(
  loading ? (
    <p>Loading...</p>
  ):(
    <Layout>
      <title>Dayton Freight - Logout</title>
      <div className="text-center">
        <h1 className="text-2xl mb-2.5">You are now logged out!</h1>
        <p>
          <a href={`https://employee.daytonfreight.com/`}>
            Return to Login
          </a>
        </p>
      </div>
      {iFrameUrl && <iframe title="oidc-signout" width="0" height="0" src={iFrameUrl}></iframe>}
    </Layout>
  ));
}