import { SelectHTMLAttributes } from "react"
import { Label } from "../label";
import { useField } from "formik";

export const Select = ({ label, name, className, required, ...props }: SelectHTMLAttributes<HTMLSelectElement> & { label?: string, name: string }) => {
    const [field, meta] = useField(name);
    
    return (
        <div className={className}>
            {label && <Label
                htmlFor={name}
                value={label}
                required={required}
                className="mb-2 font-medium"
            />}
            <select name={name} {...props}
                className="appearance-auto border w-full rounded-md border-gray-300 focus:shadow-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-3 placeholder:text-gray-300 placeholder:font-normal"
                onChange={props.onChange ?? field.onChange}
                onBlur={props.onBlur ?? field.onBlur}
                value={props.value ?? field.value}
            />
            {meta.error && meta.touched && (<label className="block text-xs text-red-500 mt-2">{meta.error}</label>)}
        </div>
    )
}