import { PageError } from "../../forms/errors/pageError";

export default function PageErrors({
  errors,
  className,
}: {
  errors: PageError[];
  className?: string;
}) {
  return (
    <ul className={`${className} text-red-500`}>
      {errors.map((error, index) => {
        return <li key={`error-${index}`}>{error.message}</li>;
      })}
    </ul>
  );
}
