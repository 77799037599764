import { Form, Formik } from "formik";
import PageErrors from "../../errors/pageErrors";
import { useContext, useState } from "react";
import { PageError } from "../../forms/errors/pageError";
import * as Yup from "yup";
import VerificationCodeInput from "../verificationCodeInput";
import Button from "../../forms/button";
import { VerificationCodeModel } from "../sendVerificationCode";

export default function ValidateVerificationCode({
  model,
  type,
  onNext,
  onLast,
}: {
  model: VerificationCodeModel;
  type: "SignUp" | "ResetPassword";
  onNext: () => void;
  onLast: () => void;
}) {
  const [pageErrors, setPageErrors] = useState<PageError[]>([]);
  const validateVerificationCodeValidationSchema = Yup.object().shape({});

  async function onSubmit(values: VerificationCodeModel) {
    var response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/account/${
        type == "SignUp" ? "signup" : "reset-password"
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Step: "ValidateVerificationCode",
          VerificationCode: values.verificationCode,
          VerificationCodeDestination: values.verificationCodeDestination,
          State: model.state,
          IV: model.iv,
        }),
      }
    );

    var result = await response.json();
    if (response.status == 200) {
      model.state = result.state; 
      model.iv = result.iv; 
      onNext();
    } else if (response.status >= 400) {
      setPageErrors([...result.page]);
    }
  }

  return (
    <>
      <h1 className="font-bold text-5xl lg:text-2xl mb-2.5">Enter Verification Code</h1>
      <p className="mb-5">
        Enter the one time passcode that was sent to{" "}
        {model.verificationCodeDestination?.maskedValue}.
      </p>
      <PageErrors errors={pageErrors} className="mb-5" />
      <Formik
        initialValues={{ ...model }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={validateVerificationCodeValidationSchema}
      >
        {({isSubmitting}) => (
          <Form>
            <VerificationCodeInput name="verificationCode" />
            <div className="text-center">
              <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>Verify</Button>
              <p className={`mt-8 mb-2 lg:mb-0 lg:mt-5 text-lg lg:text-sm`}>Didn't receive the verification code?</p>
              <a onClick={onLast} 
                  className="inline-block cursor-pointer"
                  target="_self">
                Resend Verification Code
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
