import Layout from "../components/layout";
import SignUp from "../components/signUp";

export default function SignUpPage() {
  return (
    <Layout>
      <title>Dayton Freight - Sign up</title>
      <SignUp />
    </Layout>
  );
}
