export default function Layout({ children }: { children?: any }) {
  return (
    <>

          <div className="flex min-h-screen">
            <div className={`bg-white flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:justify-center`}>
              <div className="mx-auto w-full lg:w-96">
                <div className={"mx-16 text-4xl lg:text-lg lg:mr-0 lg:ml-0"}>
                  <img src="./../../DF-Logo-Color.png" className="w-[500px] justify-center lg:w-[250px] mb-5" alt=""/>    
                    {children}
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-center hidden lg:block relative w-0 flex-1">
              <img src="./../../bluetruck.jpg" className="absolute inset-0 h-full w-full object-cover bg-indigo-900" alt=""></img>
            </div>
          </div>
    </>
  );
}
