import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import ResetPasswordPage from './pages/ResetPassword';
import SignUpPage from './pages/SignUp';

function App() {
  const router = createBrowserRouter([
    {
      path: "/account/login",
      element: <LoginPage /> 
    },
    {
      path: "/account/logout",
      element: <LogoutPage /> 
    },
    {
      path: "/account/reset-password",
      element: <ResetPasswordPage /> 
    },
    {
      path: "/account/signup",
      element: <SignUpPage /> 
    }  
  ]);

  return (<RouterProvider router={router} />)
}

export default App;
