import { useContext, useEffect, useReducer, useState } from "react";
import { preventNaNCharactersKeyDownChange } from "../../utils/preventNaNCharacters";
import { useField } from "formik";

export default function VerificationCodeInput({ name }: { name: string }) {
  const validKeyInputs = ["Backspace", "ArrowLeft", "ArrowRight"];
  // Safari is awful to work with. Had to add extra otpInput value in order to allow "From Messages" pasting to work.
  const [otpInput, setOtpInput] = useState<string[]>(["", "", "", "", "", "", ""]);
  const [, , helpers] = useField(name);

  function moveToInput(event: any, attribute: string) {
    try {
      var nextFocusId = event.currentTarget.getAttribute(attribute);
      if (nextFocusId) {
        document.getElementById(nextFocusId)?.focus();
        (document.getElementById(nextFocusId) as HTMLInputElement).select();
      }
    } catch {
      return;
    }
  }

  function onPaste(event: React.ClipboardEvent<HTMLInputElement>) {
    event.preventDefault();
    const regex = /[eE/./+/-]/g;
    if (
      isNaN(parseInt(event.clipboardData.getData("text/plain"))) &&
      event.clipboardData.getData("text/plain").length > 6
    )
      return;
    var clipboardData = event.clipboardData
      .getData("text/plain")
      .replaceAll(regex, "")
      .split("");
    setOtpInput(
      otpInput.map((c, i) => {
        return clipboardData[i] ?? "";
      })
    );
  }

  useEffect(() => {
    helpers.setValue(otpInput.slice(0,6).join(''))
  }, [otpInput]);

  return (
    <div className={`flex flex-rows items-center justify-center gap-x-5 mt-16 mb-1 lg:gap-x-3 lg:max-w-lg`}>
      {otpInput.map((element: any, index: number) => (
        <input
          key={index}
          className={`h-24 lg:h-12 text-5xl lg:text-xl w-full border caret-transparent rounded-[4px] w-12 py-2.5 border-gray-300 focus:outline-none focus:border-black focus:shadow-inner py-4 text-center font-medium ${
            otpInput[index] ?? "select-none"
          } ${index == 6 ? 'hidden': 'visible'}`}
          defaultValue={0}
          type="number"
          pattern="[0-9]*"
          maxLength={1}
          inputMode="numeric"
          autoComplete="one-time-code"
          id={`otp-${index}`}
          data-next-focus-id={`otp-${index + 1}`}
          data-prev-focus-id={`otp-${index - 1}`}
          autoFocus={index == 0 ? true : false}
          required
          onChange={(event) => {
            preventNaNCharactersKeyDownChange(event);
            if (event.currentTarget.value.length == 6){
              setOtpInput(
                otpInput.map((c, i) => {
                  return event.target.value[i] ?? "";
                })
              );
              return;
            }
            event.currentTarget.value = event.currentTarget.value[event.currentTarget.value.length-1]
            if (
              isNaN(parseInt(event.currentTarget.value)) &&
              !validKeyInputs.includes(event.currentTarget.value)
            ) {
              return;
            }
            setOtpInput(otpInput.map((c, i) => {
              return i == index ? event.currentTarget.value : c
            }));
            moveToInput(event, "data-next-focus-id");
          }}
          onKeyDown={(event) => {
            if (
              event.key.toString().length > 1 ||
              event.ctrlKey ||
              event.altKey
            ) {
              if (event.key.toString() == "ArrowRight")
                moveToInput(event, "data-next-focus-id");
              else if (
                (event.key === "Backspace" && element === "") ||
                event.key === "ArrowLeft"
              )
                moveToInput(event, "data-prev-focus-id");
              else if ( event.key == "Backspace")
                setOtpInput(otpInput.map((c, i) => {
                  return i == index ? "" : c
                }));
            }
          }}
          value={index < 6 ? parseInt(otpInput[index]) : 0}
          onPaste={onPaste}
        />
      ))}
    </div>
  );
}
