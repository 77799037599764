import { useContext } from "react";
import { Link } from "react-router-dom";

export default function SignUpComplete({ returnUrl }: { returnUrl?: string }) {

  return (
    <div className="text-center">
      <h1 className={`text-5xl lg:text-2xl mb-2.5`}>Account registered successfully</h1>
      {returnUrl ? (
        <Link
        to={returnUrl}
          className="inline-block mt-5"
        >
          Return to Login
        </Link>
      ) : (
        <Link
          to="https://employee.daytonfreight.com"
        >
          Return to Login
        </Link>
      )}
    </div>
  );
}
