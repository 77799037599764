import { Form, Formik, FormikProps, FormikValues } from "formik";
import { useContext, useState } from "react";
import * as Yup from "yup";
import Input from "../../forms/input";
import Button from "../../forms/button";
import { ResetPasswordModel } from "..";
import PageErrors from "../../errors/pageErrors";
import { PageError } from "../../forms/errors/pageError";
import {
  preventNaNCharactersKeyDown,
  preventNaNCharactersPaste,
} from "../../utils/preventNaNCharacters";
import { Link } from "react-router-dom";

export default function UserInformation({
  model,
  onNext,
}: {
  model: ResetPasswordModel;
  onNext: (state?: string, iv?: string) => void;
}) {
  const [userName, setUserName] = useState<string>("");
  const [pageErrors, setPageErrors] = useState<PageError[]>([]);
  const userInformationValidationSchema = Yup.object().shape({
    UserName: Yup.string()
      .required("Employee number is required.")
      .test("no-leading-zero", "Invalid Employee Number.", (value, context) => {
        return !value.toString().startsWith("0");
      }),
  });

  async function onSubmit(values: FormikValues) {
    var response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/account/reset-password`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Step: "UserInformation",
          UserName: values.UserName.toString(),
          returnUrl:model.returnUrl,
          State: null,
          IV: null,
        }),
      }
    );

    var result = await response.json();
    if (response.status == 200) {
      model.verificationCodeDestination = result.verificationCodeDestination;
      model.verificationCodeDestinations = result.verificationCodeDestinations;
      model.state = result.state;
      model.iv = result.iv;

      onNext();
    } else if (response.status >= 400) {
      setPageErrors([...result.page]);
    }
  }
  return (
    <>
      <h1 className={`text-5xl mb-5 lg:text-[1.65rem] font-bold lg:mb-2.5 text-primary`}>Reset Password</h1>
      <PageErrors errors={pageErrors} className="mb-5" />
      <Formik<ResetPasswordModel>
        initialValues={{ ...model }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={userInformationValidationSchema}
      >
        {(props: FormikProps<ResetPasswordModel>) => (
          <Form>
            <Input
              label="Employee Number"
              autoComplete="username"
              autoFocus
              type="text"
              id="userName"
              name="UserName"
              placeholder="Employee Number"
              inputMode="numeric"
              onChange={(event) => {
                props.handleChange(event);
                setUserName(event.currentTarget.value);
              }}
              onBlur={props.handleBlur}
              value={props.values.userName}
              onKeyDown={preventNaNCharactersKeyDown}
              onPaste={preventNaNCharactersPaste}
              required
            />
            <div className="text-center">
              <Button
                type="submit"
                className={"mb-5"}
                disabled={
                  userName.length > 0 || props.isSubmitting ? false : true
                }
                loading={props.isSubmitting}
              >
                Reset Password
              </Button>
              {model?.returnUrl ? (
                <Link
                to={model.returnUrl}
                >
                  Return to Login
                </Link>
              ) : (
                <Link to="https://employee.daytonfreight.com">
                  Return to Login
                </Link>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
