import { useContext, useState } from "react";
import { ResetPasswordModel } from "..";
import { PageError } from "../../forms/errors/pageError";
import { Form, Formik, FormikValues } from "formik";
import PageErrors from "../../errors/pageErrors";
import Input from "../../forms/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/sharp-light-svg-icons";
import ReactPasswordChecklist from "react-password-checklist";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import Button from "../../forms/button";
import { Link } from "react-router-dom";

type ConfirmNewPasswordValues = {
  password: string;
  confirmPassword: string;
  step: "ConfirmNewPassword";
};

export default function ConfirmNewPassword({
  model,
  onNext,
}: {
  model: ResetPasswordModel;
  onNext: (state?: string, iv?: string) => void;
}) {
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [pageErrors, setPageErrors] = useState<PageError[]>([]);
  const [showPasswordConfirm, setShowPasswordConfirm] =
    useState<boolean>(false);

  async function onSubmit(values: ConfirmNewPasswordValues) {
    var response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/account/reset-password`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Step: values.step,
          UserName: model.userName?.toString(),
          Password: values.password,
          ConfirmPassword: values.confirmPassword,
          State: model.state,
          IV: model.iv,
        }),
      }
    );

    var result = await response.json();
    if (response.status == 200) {
      model.verificationCodeDestination = result.verificationCodeDestination;
      model.verificationCodeDestinations = result.verificationCodeDestinations;
      onNext(result.State, result.IV);
    } else if (response.status >= 400) {
      setPageErrors([...result.page]);
    }
  }

  return (
    <>
      <h1 className={`text-5xl mb-5 lg:text-[1.65rem] font-bold lg:mb-2.5 text-primary`}>Reset Password</h1>
      <PageErrors errors={pageErrors} className="mb-5" />
      <Formik<ConfirmNewPasswordValues>
        initialValues={{
          password: "",
          confirmPassword: "",
          step: "ConfirmNewPassword",
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div className="relative">
              <Input
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                required
              />
              <div
                className={`absolute inset-y-[60px] lg:inset-y-9 right-5 text-gray-300`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </div>
            </div>

              <div className="relative">
                <Input
                  label="Confirm Password"
                  type={showPasswordConfirm ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  required
                />
                <div
                  className={`absolute inset-y-[60px] lg:inset-y-9 right-5 text-gray-300`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPasswordConfirm(!showPasswordConfirm);
                  }}
                >
                  {showPasswordConfirm ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </div>
              </div>

              <ReactPasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={8}
                onChange={(isValid: boolean) => {
                  setIsPasswordValid(isValid);
                }}
                iconComponents={{
                  ValidIcon: (
                    <FontAwesomeIcon
                      className="mt-1.5 mr-2 text-green-500"
                      icon={faCheck}
                    />
                  ),
                  InvalidIcon: (
                    <FontAwesomeIcon
                      className="mt-1.5 mr-2 text-gray-300"
                      icon={faCheck}
                    />
                  ),
                }}
                messages={{
                  specialChar: "Password has a special character.",
                }}
                value={values.password}
                valueAgain={values.confirmPassword}
              />

              <div className="text-center">
                <Button
                  type="submit"
                  className={"mb-5"}
                  disabled={!isPasswordValid || isSubmitting}
                  loading={isSubmitting}
                >
                  Reset Password
                </Button>
                {model?.returnUrl ? (
                  <Link
                  to={model.returnUrl}
                  >
                    Return to Login
                  </Link>
                ) : (
                  <Link
                    to="https://employee.daytonfreight.com"
                  >
                    Return to Login
                  </Link>
                )}
              </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
