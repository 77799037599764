import { useField } from "formik";
import { HTMLAttributes, useContext } from "react";

export default function Input({
  id,
  name,
  label,
  pattern,
  type,
  value,
  placeholder,
  required,
  ...props
}: { name: string, label: string, autoComplete? : string, pattern?: string, type: "text" | "number" | "password", value? : any, placeholder: string, required? : boolean } & HTMLAttributes<HTMLInputElement>) {
  const [field, meta,] = useField(name);

  return (
    <div className="my-5">
      <label htmlFor={name} className={`block font-bold lg:font-medium mb-1.5 text-2xl lg:text-sm`}>{label}</label>
      <input
        id={id}
        type={type}
        pattern={pattern}
        placeholder={placeholder}
        name={name}
        onChange={props.onChange ?? field.onChange}
        value={field.value}
        required={required}
        className={`w-full text-5xl lg:text-lg border rounded-[4px] px-4 py-2.5 border-gray-300 focus:outline-none focus:border-primary focus:ring-[3px] focus:ring-gray-200`}
        {...props}
      />
      {meta.error && meta.touched && (<label className={`block text-sm text-red-500 mt-2`}>{meta.error}</label>)}
    </div>
  );
}
