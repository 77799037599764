import { HTMLAttributes } from "react";

export default function Button({
  children,
  type,
  disabled,
  loading,
  ...props
}: { children: any, type: "button" | "submit" | "reset", disabled? : boolean, loading?: boolean } & HTMLAttributes<HTMLButtonElement>) {
  return (
    <button {...props} type={type} disabled={disabled ?? false} className={`hover:bg-primary hover:text-white text-primary border border-primary rounded-[4px] px-20 py-3 mt-10 font-medium w-full hover:bg-primary/90 disabled:border-zinc-400 disabled:bg-white disabled:text-gray-400 transition-all${props.className ? ` ${props.className}` : ""}`}>
      {loading ? <div className="flex justify-center"><div className="loader"></div></div> : <div>{children}</div>}
    </button>
  );
}
