import { useEffect, useState } from "react";
import SendVerificationCode, { VerificationCodeModel } from "../verification/sendVerificationCode";
import ValidateVerificationCode from "../verification/validateVerificationCode";
import SignUpComplete from "./signUpComplete";
import UserInformation from "./userInformation";
import { VerificationCodeDestinationModel } from "../../types/verificationCodeDestinationModel";
import { useLocation, useSearchParams } from "react-router-dom";

export type SignUpModel = {
  userName?: string;
  password?: string;
  confirmPassword?: string;
  step: string;
  verificationCode?: number;
  verificationCodeDestination?: VerificationCodeDestinationModel;
  verificationCodeDestinations?: VerificationCodeDestinationModel[];
  returnUrl?: string;
  state?: string;
  iv?: string;
} & VerificationCodeModel;

export default function SignUp() {
  const {state} = useLocation()
  const [searchParams] = useSearchParams();
  const [model, setModel] = useState<SignUpModel>({ step: "UserInformation", returnUrl:state?.returnUrl ?? searchParams.get("returnUrl")});

  const onPopState = () => {
    // TODO : load the state.
  };

  const onPushState = () => {
    //window.history.pushState(model, "");
  };

  //useEffect(() => {
  //  window.addEventListener("popstate", onPopState);
  //  return () => window.removeEventListener("popstate", onPopState);
  //}, [onPopState]);

  function renderStep(step: string) {
    switch (step) {
      case "UserInformation":
        return (
          <UserInformation
            model={model}
            onNext={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "SendVerificationCode",
              }));
            }}
          />
        );
      case "SendVerificationCode":
        return (
          <SendVerificationCode
            model={model}
            type="SignUp"
            onNext={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "ValidateVerificationCode",
              }));
            }}
          />
        );
      case "ValidateVerificationCode":
        return (
          <ValidateVerificationCode
            model={model}
            type="SignUp"
            onNext={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "CompleteSignUp"
              }));
            }}
            onLast={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "SendVerificationCode",
              }))
            }}
          />
        );
      case "CompleteSignUp":
        return <SignUpComplete returnUrl={model.returnUrl} />;
      default:
        return <></>;
    }
  }

  return <>{renderStep(model.step)}</>;
}
