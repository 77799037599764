import ResetPassword from "../components/resetPassword";
import Layout from "../components/layout";

export default function ResetPasswordPage() {
  return (
    <Layout>
      <title>Dayton Freight - Reset</title>
      <ResetPassword />
    </Layout>
  );
}
