import { Form, Link, useLocation, useSearchParams } from "react-router-dom";
import Input from "../components/forms/input";
import {
  preventNaNCharactersKeyDown,
  preventNaNCharactersPaste,
} from "../components/utils/preventNaNCharacters";
import { Formik } from "formik";
import { FormEvent, useEffect, useState } from "react";
import { PageError } from "../components/forms/errors/pageError";
import PageErrors from "../components/errors/pageErrors";
import Button from "../components/forms/button";
import Layout from "../components/layout";
import { faEye, faEyeSlash } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type LoginModel = {
  userName: number | string;
  password: string;
};

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [pageErrors, setPageErrors] = useState<PageError[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [returnUrl, setReturnUrl] = useState<string>()

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
    values: LoginModel,
    resetForm: any
  ) {
    event.preventDefault();

    var response: Response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/login`,
      {
        body: JSON.stringify({
          userName: values.userName.toString(),
          password: values.password,
          returnUrl: state?.returnUrl ?? searchParams.get("returnUrl"),
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    var result = await response.json();
    if (response.ok) {
      const { returnUrl } = result;
      const { ReturnUrl } = result;
      window.location.href = returnUrl ?? ReturnUrl;
    } else if (response.status >= 400) {
      setPageErrors([...result.page]);
    }
  }

  useEffect(() =>{
    setReturnUrl(`${process.env.REACT_APP_API_URL}/account/login?returnUrl=` + (state?.returnUrl ? encodeURIComponent(state.returnUrl) : encodeURIComponent(searchParams.get("returnUrl") ?? "")))
  }, [])

  return (
    <Layout>
      <title>Dayton Freight - Login</title>
      <PageErrors errors={pageErrors} className="mb-5" />
      <Formik<LoginModel>
        initialValues={{
          userName: "",
          password: "",
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={() => {}}
      >
        {({ values, resetForm, isSubmitting }) => (
          <Form
            onSubmit={async (event) =>
              await handleSubmit(event, values, resetForm)
            }
          >
            <div>
              <h1 className={`text-5xl mb-10 lg:text-[1.65rem] font-bold lg:mb-2.5 text-primary`}>
                Login
              </h1>
              <p className={`text-gray-500`}>
                Don't have an account yet?{" "}
                <Link
                  className="text-primary underline hover:bg-gray-100"
                  to={`${process.env.REACT_APP_API_URL}/account/signup`}
                  state={{
                    returnUrl:
                      returnUrl,
                  }}
                >
                  Sign Up
                </Link>
              </p>

              <Input
                label="Employee Number"
                autoComplete="username"
                inputMode="numeric"
                autoFocus
                type="text"
                id="userName"
                name="userName"
                placeholder="Employee Number"
                tabIndex={1}
                value={values.userName}
                onKeyDown={preventNaNCharactersKeyDown}
                onPaste={preventNaNCharactersPaste}
                required
              />

              <div className="relative">
                <Input
                  label="Password"
                  autoComplete="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  tabIndex={2}
                  placeholder="Password"
                  name="password"
                  required
                />
                <div
                  className={`inset-y-[60px] lg:inset-y-9 right-5 absolute text-gray-300`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {" "}
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </div>
              </div>

              <Link
                to={`${process.env.REACT_APP_API_URL}/account/reset-password`}
                state={{
                  returnUrl: returnUrl,
                }}
                tabIndex={3}
              >
                Forgot Password?
              </Link>

              <Button
                type="submit"
                disabled={
                  (values.userName && values.password) || isSubmitting
                    ? false
                    : true
                }
                aria-label="Login"
                loading={isSubmitting}
                tabIndex={4}
              >
                Login
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}
