import { useEffect, useState } from "react";
import { VerificationCodeDestinationModel } from "../../types/verificationCodeDestinationModel";
import UserInformation from "./userInformation";
import SendVerificationCode from "../verification/sendVerificationCode";
import ResetPasswordComplete from "./resetPasswordComplete";
import ConfirmNewPassword from "./confirmNewPassword";
import ValidateVerificationCode from "../verification/validateVerificationCode";
import { useLocation, useSearchParams } from "react-router-dom";

export type ResetPasswordModel = {
  userName?: string;
  password?: string;
  confirmPassword?: string;
  step:
    | "UserInformation"
    | "SendVerificationCode"
    | "ValidateVerificationCode"
    | "ConfirmNewPassword"
    | "CompleteResetPassword";
  verificationCode?: number;
  verificationCodeDestination?: VerificationCodeDestinationModel;
  verificationCodeDestinations?: VerificationCodeDestinationModel[];
  returnUrl?: string;
  state?: string;
  iv?: string;
};

export default function ResetPassword() {
  const {state} = useLocation()
  const [searchParams] = useSearchParams();
  const [model, setModel] = useState<ResetPasswordModel>({ step: "UserInformation", returnUrl:state?.returnUrl ?? searchParams.get("returnUrl") });

  const onPopState = () => {
    // TODO : load the state.
  };

  const onPushState = () => {
    window.history.pushState(model, "");
  };

  useEffect(() => {
    window.addEventListener("popstate", onPopState);
    return () => window.removeEventListener("popstate", onPopState);
  }, [onPopState]);

  useEffect(() => {
    onPushState();
  }, [model.step]);

  function renderStep(
    step:
      | "UserInformation"
      | "SendVerificationCode"
      | "ValidateVerificationCode"
      | "ConfirmNewPassword"
      | "CompleteResetPassword"
  ) {
    switch (step) {
      case "UserInformation":
        return (
          <UserInformation
            model={model}
            onNext={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "SendVerificationCode",
              }));
            }}
          />
        );
      case "SendVerificationCode":
        return (
          <SendVerificationCode
            model={model}
            type="ResetPassword"
            onNext={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "ValidateVerificationCode",
              }));
            }}
          />
        );
      case "ValidateVerificationCode":
        return (
          <ValidateVerificationCode
            model={model}
            type="ResetPassword"  
            onNext={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "ConfirmNewPassword",
              }));
            }}
            onLast={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "SendVerificationCode",
              }))
            }}
          />
        );
      case "ConfirmNewPassword":
        return (
          <ConfirmNewPassword
            model={model}
            onNext={() => {
              setModel((previousState) => ({
                ...previousState,
                step: "CompleteResetPassword",
              }));
            }}
          />
        );
      case "CompleteResetPassword":
        return <ResetPasswordComplete returnUrl={model.returnUrl} />;
      default:
        return <></>;
    }
  }

  return <>{renderStep(model.step)}</>;
}
