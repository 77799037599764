export function preventNaNCharactersKeyDown(event: React.KeyboardEvent<HTMLInputElement>){
    const regex = /[^0-9]/g
    if (event.key.length == 1 && event.key.match(regex))
        event.preventDefault()
    return
}
export function preventNaNCharactersKeyDownChange(event: React.ChangeEvent<HTMLInputElement>){
    const regex = /[^0-9]/g
    if (event.currentTarget.value.length == 1 && event.currentTarget.value.match(regex))
        event.preventDefault()
    return
}

export function preventNaNCharactersPaste(event: React.ClipboardEvent<HTMLInputElement>){
    event.preventDefault()
    const regex = /[^0-9]/g
    const parsedData = parseInt(event.clipboardData.getData("text/plain").replaceAll(regex, ''));
    (event.target as any).value = parsedData
    return
}