import { Form, Formik, FormikProps, FormikValues } from "formik";
import { SignUpModel } from "..";
import Input from "../../forms/input";
import {
  preventNaNCharactersKeyDown,
  preventNaNCharactersPaste,
} from "../../utils/preventNaNCharacters";
import { useContext, useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/sharp-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import Button from "../../forms/button";
import * as Yup from "yup";
import PageErrors from "../../errors/pageErrors";
import { PageError } from "../../forms/errors/pageError";
import { Link } from "react-router-dom";

export default function UserInformation({
  model,
  onNext,
}: {
  model: SignUpModel;
  onNext: () => void;
}) {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [pageErrors, setPageErrors] = useState<PageError[]>([]);
  const [showPasswordConfirm, setShowPasswordConfirm] =
    useState<boolean>(false);

  const userInformationValidationSchema = Yup.object().shape({
    UserName: Yup.string()
      .required("Employee number is required.")
      .test("no-leading-zero", "Invalid Employee Number.", (value, context) => {
        return !value.toString().startsWith("0");
      }),
  });

  async function onSubmit(values: FormikValues) {
    var response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/account/signup`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Step: "UserInformation",
          returnUrl:model.returnUrl,
          UserName: values.UserName.toString(),
          Password: values.Password,
          ConfirmPassword: values.ConfirmPassword,
          State: null,
          IV: null,
        }),
      }
    );

    var result = await response.json();
    if (response.status == 200) {
      model.verificationCodeDestination = result.verificationCodeDestination;
      model.verificationCodeDestinations = result.verificationCodeDestinations;
      model.state = result.state;
      model.iv = result.iv;

      onNext();
    } else if (response.status >= 400) {
      setPageErrors([...result.page]);
    }
  }

  return (
    <>
      <h1 className={`text-5xl mb-5 lg:text-[1.65rem] font-bold lg:mb-2.5 text-primary`}>Sign Up</h1>
      <PageErrors errors={pageErrors} className="mb-5" />
      <Formik<SignUpModel>
        initialValues={{ ...model }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={userInformationValidationSchema}
      >
        {(props: FormikProps<SignUpModel>) => (
          <Form>
            <Input
              label="Employee Number"
              autoComplete="username"
              inputMode="numeric"
              autoFocus
              type="text"
              id="userName"
              name="UserName"
              placeholder="Employee Number"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.userName}
              onKeyDown={preventNaNCharactersKeyDown}
              onPaste={preventNaNCharactersPaste}
              required
            />

            <div className="relative">
              <Input
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                name="Password"
                placeholder="Password"
                onChange={(event) => {
                  props.handleChange(event);
                  setPassword(event.currentTarget.value);
                }}
                onBlur={props.handleBlur}
                value={props.values.password}
                required
              />
              <div
                className={`absolute inset-y-[60px] lg:inset-y-9 right-5 text-gray-300`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </div>
            </div>

              <div className="relative">
                <Input
                  label="Confirm Password"
                  type={showPasswordConfirm ? "text" : "password"}
                  id="confirmPassword"
                  name="ConfirmPassword"
                  placeholder="Confirm Password"
                  onChange={(event) => {
                    props.handleChange(event);
                    setConfirmPassword(event.currentTarget.value);
                  }}
                  onBlur={props.handleBlur}
                  value={props.values.confirmPassword}
                  required
                />
                <div
                  className={`absolute inset-y-[60px] lg:inset-y-9 right-5 text-gray-300`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPasswordConfirm(!showPasswordConfirm);
                  }}
                >
                  {showPasswordConfirm ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </div>
              </div>
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={8}
                onChange={(isValid: boolean) => {
                  setIsPasswordValid(isValid);
                }}
                iconComponents={{
                  ValidIcon: (
                    <FontAwesomeIcon
                      className="mt-1.5 mr-2 text-green-500"
                      icon={faCheck}
                    />
                  ),
                  InvalidIcon: (
                    <FontAwesomeIcon
                      className="mt-1.5 mr-2 text-gray-300"
                      icon={faCheck}
                    />
                  ),
                }}
                messages={{
                  specialChar: "Password has a special character.",
                }}
                value={password}
                valueAgain={confirmPassword}
              />

              <div className="text-center">
                <Button
                  type="submit"
                  className={"mb-5"}
                  disabled={!isPasswordValid || props.isSubmitting}
                  loading={props.isSubmitting}
                >
                  Sign Up
                </Button>
                {model?.returnUrl ? (
                  <Link
                  to={model.returnUrl}
                  >
                    Return to Login
                  </Link>
                ) : (
                  <Link
                    to="https://employee.daytonfreight.com"
                  >
                    Return to Login
                  </Link>
                )}
              </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
