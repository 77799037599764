import { Form, Formik, FormikProps, Field } from "formik";
import Button from "../../forms/button";
import { useContext, useState } from "react";
import { PageError } from "../../forms/errors/pageError";
import PageErrors from "../../errors/pageErrors";
import { VerificationCodeDestinationModel } from "../../../types/verificationCodeDestinationModel";
import { Select } from "../../forms/select";

export type VerificationCodeModel = {
  verificationCodeDestination?: VerificationCodeDestinationModel;
  verificationCodeDestinations?: VerificationCodeDestinationModel[];
  verificationCode?: number,
  step: string,
  state?: string;
  iv?: string;
};

type SendVerificationCodeValues = {
  contactType: "Email" | "SMS" | "Call";
  selectedEmailDestination: string | null | undefined;
  selectedSmsDestination: string | null | undefined;
  selectedCallDestination: string | null | undefined;
}

export default function SendVerificationCode({
  model,
  type,
  onNext,
}: {
  model: VerificationCodeModel;
  type: "SignUp" | "ResetPassword";
  onNext: () => void;
}) {
  const [pageErrors, setPageErrors] = useState<PageError[]>([]);
  const emailDestinations = model.verificationCodeDestinations?.filter(item => item.type === 'Email') ?? null;
  const smsDestinations = model.verificationCodeDestinations?.filter(item => item.type === 'SMS') ?? null;
  const callDestinations = model.verificationCodeDestinations?.filter(item => item.type === 'Call') ?? null;

  async function onSubmit(values: SendVerificationCodeValues) {
    model.verificationCodeDestination = model.verificationCodeDestinations!.filter(destination => destination.type === values.contactType &&
      destination.maskedValue === (values.contactType === "Email" ? values.selectedEmailDestination 
      : (values.contactType === "SMS" ? values.selectedSmsDestination : values.selectedCallDestination)))[0];

    var response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/account/${
        type == "SignUp" ? "signup" : "reset-password"
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Step: "SendVerificationCode", 
          VerificationCodeDestinations: model.verificationCodeDestinations,
          VerificationCodeDestination: model.verificationCodeDestination,
          State: model.state,
          IV: model.iv,
        }),
      }
    );

    var result = await response.json();
    if (response.status == 200) {
      model.state = result.state; 
      model.iv = result.iv; 
      onNext();
    } else if (response.status >= 400) {
      setPageErrors([...result.page]);
    }
  }

  return (
    <Formik<SendVerificationCodeValues>
      initialValues={{
        contactType: "Email",
        selectedEmailDestination: emailDestinations ? emailDestinations[0]?.maskedValue : null,
        selectedSmsDestination: smsDestinations ? smsDestinations[0]?.maskedValue : null,
        selectedCallDestination: callDestinations ? callDestinations[0]?.maskedValue : null
      }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
    >
      {(props: FormikProps<any>) => (
        <Form>
          <h1 className="font-bold text-5xl lg:text-2xl mb-2.5">Send Verification Code</h1>
          <p className="mb-5">
            Select where you would like to receive your code.
          </p>
          <PageErrors errors={pageErrors} className="mb-5" />

          {(model.verificationCodeDestinations && model.verificationCodeDestinations.length > 0) ? (
            <>

              {
                emailDestinations && emailDestinations.length > 0 ?
                <div className="grid grid-cols-5 gap-4 mb-8 ml-2 mr-2">
                  <label className="mt-3">
                    <Field type="radio" name="contactType" value="Email" className="mr-1"/>
                    Email
                  </label>
                  <div className="col-span-4">
                    <Select name= "selectedEmailDestination">
                      {
                        emailDestinations.map((item, index) => {
                          return (
                            <option key={index} value={item.maskedValue}>{item.maskedValue}</option>
                          );
                        })
                      }
                    </Select>
                  </div>
                </div>
                :
                <></>
              }

              {
                smsDestinations && smsDestinations.length > 0 ?
                <div className="grid grid-cols-5 gap-4 mb-8 ml-2 mr-2">
                  <label className="mt-3">
                    <Field type="radio" name="contactType" value="SMS" className="mr-1"/>
                    Text
                  </label>
                  <div className="col-span-4">
                    <Select name= "selectedSmsDestination">
                      {
                        smsDestinations.map((item, index) => {
                          return (
                            <option key={index} value={item.maskedValue}>{item.maskedValue}</option>
                          );
                        })
                      }
                    </Select>
                  </div>
                </div>
                :
                <></>
              }

              {
                callDestinations && callDestinations.length > 0 ?
                <div className="grid grid-cols-5 gap-4 mb-8 ml-2 mr-2">
                  <label className="mt-3">
                    <Field type="radio" name="contactType" value="Call" className="mr-1"/>
                    Call
                  </label>
                  <div className="col-span-4">
                    <Select name= "selectedCallDestination">
                      {
                        callDestinations.map((item, index) => {
                          return (
                            <option key={index} value={item.maskedValue}>{item.maskedValue}</option>
                          );
                        })
                      }
                    </Select>
                  </div>
                </div>
                :
                <></>
              }

              <p className={`text-lg lg:text-sm mt-2`}>
                * Message and data rates may apply for text option.
              </p>

              <div className="text-center">
                <Button
                  type="submit"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  autoFocus={true}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <div className="text-center">
              <p className="lg:text-lg mt-2 text-2xl">
                No delivery methods available to send your one-time-passcode to.
              </p>
              <p className="lg:text-lg mt-5 text-2xl">
                Please update your contact information in UKG and then retry the
                sign-up process.
              </p>
            </div>
          )}

        </Form>
      )}
    </Formik>
  );
}
